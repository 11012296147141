"use strict";

$(function () {
    // Scroll To
    if ($(".scroll").length > 0) {
        $(".scroll").click(function (e) {
            e.preventDefault();
            var dest = $(this).attr('href');
            var distance = $("#middle-menu").hasClass("fixed") ? 100 : 100;
            $('html, body').animate({
                scrollTop: $(dest).offset().top - distance
            }, 1000);
        });
    }

    if ($(".scrollSmooth").length > 0) {
        $(".scrollSmooth").click(function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: $(this.hash).offset().top }, 500);
        });
    }

    if ($(".representative .panel-cta").length > 0) {
        var back = '<div class="col-md-12"><a href="javascript:history.back()" title="title" class="form-nav"> <i aria-hidden="true" class="fa fa-chevron-left"> </i><span>BACK </span></a></div>';
        $(".representative .panel-cta").before(back);
    } //if

    setTimeout(function () {
        if ($(".addthis_inline_share_toolbox").html() == "" && $(".addthis_inline_share_toolbox").length > 0) {
            $(".attribution").hide();
        }
    }, 5000);
});

$.fn.scrollView = function () {
    return this.each(function () {
        var scroll_amount = $(this).data("scroll") ? $(this).data("scroll") : 0;
        $('html, body').animate({
            scrollTop: $(this).offset().top - scroll_amount
        }, 1000);
    });
};

var page = findGetParameter('goto');
if (page != undefined) {
    $(document).ready(function () {
        $('html, body').animate({
            scrollTop: $('#' + page).offset().top
        }, 500);
    });
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search.substr(1).split("&").forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
    return result;
};

function hasQuestionMark(url) {
    if (url.indexOf("?") > -1) {
        return true;
    }
    return false;
}

function customScrollToByClass(elementToScroll) {
    if ($("." + elementToScroll).length > 0) {
        $('html, body').animate({
            scrollTop: $("." + elementToScroll).offset().top
        }, 1000);
    }
}

function fireTrackingOracleSP() {
    var productCode = $("#actionprocedurepar1").val();
    operationprocedureactioncta(productCode);
}

function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

function showVideoLightbox(videoId) {
    $("#" + videoId).modal("show");
    $("#" + videoId).on('hidden.bs.modal', function (event) {
        var modal = $(this);
        var playerId = modal.find('.jwplayer').attr('id');
        if (playerId) {
            if (jwplayer(playerId).getState() == "playing" || jwplayer(playerId).getState() == "buffering") {
                jwplayer(playerId).play(false);
            }
            jwplayer(playerId).stop();
        } else {
            var playerYouTube = $("#" + videoId + "-frameId");
            playerYouTube[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            // $("#" + videoId).attr("src", $("#" + videoId + " iframe").attr("src"));
            // $("#" + videoId).attr('src', $(this).data( "src" )); 
            var memory = $(this).html();
            $(this).html(memory);
        }
    });
}

//Body NAvigation

if ($('.c-098')) {
    var generateDropdownMarkup = function generateDropdownMarkup(container) {
        var $navWrapper = container.find(".nav-wrapper");
        var $newWrapper = $('.tab-content');
        var $navPills = container.find(".nav-pills");
        var firstTextLink = $navPills.find("li:first-child a").text();
        var $items = $navPills.find("li");
        var markup = "\n                        <div class=\"dropdown dropdown-pills d-lg-none\">\n                        <button class=\"btn dropdown-toggle\" type=\"button\" id=\"dropdownMenuButton\" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\" aria-controls=\"dropdownMenu\">\n                            " + firstTextLink + "\n                        </button>\n                        <ul class=\"dropdown-menu\" aria-labelledby=\"dropdownMenuButton\" id=\"dropdownMenu\" role=\"menu\"> \n                            " + generateDropdownLinksMarkup($items) + "\n                        </ul>\n                        </div>\n                    ";
        // $navWrapper.append(markup);
        $newWrapper.before(markup);
    };

    var generateDropdownLinksMarkup = function generateDropdownLinksMarkup(items) {
        var markup = "";
        items.each(function () {
            var textLink = $(this).find("a").text();
            markup += "<li><a class=\"dropdown-item\" role=\"menuitem\" tabindex=\"-1\" href=\"#\">" + textLink + "</a></li>";
        });

        return markup;
    };

    var showDropdownHandler = function showDropdownHandler(e) {
        // works also
        //const $this = $(this);
        var $this = $(e.target);
        var $dropdownToggle = $this.find(".dropdown-toggle");
        var dropdownToggleText = $dropdownToggle.text().trim();
        var $dropdownMenuLinks = $this.find(".dropdown-menu a");
        var dNoneClass = "d-none";
        $dropdownMenuLinks.each(function () {
            var $this = $(this);
            if ($this.text() == dropdownToggleText) {
                $this.addClass(dNoneClass);
            } else {
                $this.removeClass(dNoneClass);
            }
        });
    };

    var clickHandler = function clickHandler(e) {
        e.preventDefault();
        var $this = $(this);
        var index = $this.parent().index();
        var text = $this.text();
        $this.closest(".dropdown").find(".dropdown-toggle").text("" + text);
        $this.closest($tabsToDropdown).find(".nav-pills li:eq(" + index + ") a").tab("show");
    };

    var shownTabsHandler = function shownTabsHandler(e) {
        // works also
        //const $this = $(this);
        var $this = $(e.target);
        var index = $this.parent().index();
        var $parent = $this.closest($tabsToDropdown);
        var $targetDropdownLink = $parent.find(".dropdown-menu a").eq(index);
        var targetDropdownLinkText = $targetDropdownLink.text();
        $parent.find(".dropdown-toggle").text(targetDropdownLinkText);
    };

    var $tabsToDropdown = $(".tabs-to-dropdown");

    $tabsToDropdown.each(function () {
        var $this = $(this);
        var $pills = $this.find('a[data-toggle="pill"]');

        generateDropdownMarkup($this);

        var $dropdown = $this.find(".dropdown");
        var $dropdownLinks = $this.find(".dropdown-menu a");

        $dropdown.on("show.bs.dropdown", showDropdownHandler);
        $dropdownLinks.on("click", clickHandler);
        $pills.on("shown.bs.tab", shownTabsHandler);
    });
}
//End Body Navigation

$(window).on("load", function () {
    // Find all FAQ sections
    $('section').each(function () {
        var section = $(this); // Reference to the current section

        // Find the "View More" and "View Less" buttons and additional FAQs for this section
        var viewMoreButton = section.find('.view-more');
        var viewLessButton = section.find('.view-less');

        var panelDefault = section.find('.accordion-item-default');

        if (panelDefault.length > 4) {
            viewMoreButton.removeClass('d-none'); // Show "View More" button if FAQs are more than 4
        }

        // Add a class to elements after index 4
        panelDefault.each(function (index) {
            if (index > 3) {
                $(this).addClass('additional-faqs d-none');
            }
        });

        // Add click event listener to the "View More" button
        viewMoreButton.on('click', function (e) {
            e.preventDefault();

            // Show all hidden panels and switch buttons
            panelDefault.each(function () {
                $(this).removeClass('d-none');
            });
            viewMoreButton.addClass('d-none');
            viewLessButton.removeClass('d-none');
        });

        // Add click event listener to the "View Less" button
        viewLessButton.on('click', function (e) {
            e.preventDefault();

            // Re-hide panels after index 4 and switch buttons
            panelDefault.each(function (index) {
                if (index > 3) {
                    $(this).addClass('d-none');
                }
            });
            viewLessButton.addClass('d-none');
            viewMoreButton.removeClass('d-none');
        });
    });
});

// JavaScript for Sticky Banner Logic
if ($('.c-102').length > 0) {
    window.addEventListener('scroll', function () {
        var stickyBanner = document.getElementById('sticky-banner');
        var heroSection = document.querySelector('.c-102'); // Adjust selector as needed

        if (window.scrollY > heroSection.offsetHeight) {
            stickyBanner.style.display = 'block'; // Show banner
        } else {
            stickyBanner.style.display = 'none'; // Hide banner
        }
    });
}