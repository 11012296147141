"use strict";

$(function () {
    if ($("#subscriptions").length > 0) {
        var loader = $(".loader"),
            endpoint = $(location).attr('origin'),

        //endpoint = "http://integ.neamb.codm",
        data = {
            "listId": 0,
            "newStatus": 0
        };
        var subscriptions = {
            errorBar: $(".error-bar"),
            checkMark: '<p class="p-b-sm"><img src="/assets/neamb/images/Checkmark-Icon.png" alt=""><span class="p-l-sm">You are subscribed</span></p>',
            error: function error() {
                this.errorBar.addClass("show");
                $("html, body").animate({ scrollTop: 0 }, "slow");
            },
            setButton: function setButton(el) {
                this.errorBar.removeClass("show");
                el = $(el);
                el.addClass("btn-blue");
                el.text(el.data("subscribelabel"));
                el.siblings("p").hide();
            },
            unSetButton: function unSetButton(el) {
                this.errorBar.removeClass("show");
                el = $(el);
                el.removeClass("btn-blue");
                el.text(el.data("unsubscribelabel"));
                if (!el.siblings("p").length > 0) {
                    el.before(this.checkMark);
                }
                el.siblings("p").show().find("span").text(el.data("checklabel"));
            },
            requestData: function requestData(data) {
                return $.ajax({
                    data: data,
                    dataType: 'json',
                    async: false,
                    type: 'POST',
                    url: endpoint + "/api/SettingsAndSubs/ChangeSubscription"
                });
            }, //requestData,
            permissionStopEmail: function permissionStopEmail() {
                return $.ajax({
                    dataType: 'json',
                    async: false,
                    type: 'POST',
                    url: endpoint + "/api/PermissionEmail/StopEmail"
                });
            },
            permissionResumeEmail: function permissionResumeEmail() {
                return $.ajax({
                    dataType: 'json',
                    async: false,
                    type: 'POST',
                    url: endpoint + "/api/PermissionEmail/ResumeEmail"
                });
            } //subscriptions
        };$(document).on("click", ".ctas .btn", function () {
            if (!$(this).hasClass("permissionAction")) {
                var id = $(this).data("id"),
                    status = $(this).data("status");
                data.newStatus = status == 0 ? 3 : 0;
                data.listId = id;
                $(this).data("status", data.newStatus);
                var ajax = subscriptions.requestData(data);
                if (ajax.status == 200) {
                    if (ajax.responseJSON.wasProcessed && status == 0) {
                        subscriptions.setButton(this);
                        dataLayerPush(gtm = {
                            'event': 'account',
                            'accountSection': 'settings & subscriptions',
                            'accountAction': "" + $(this).closest('.panel-cta').find("h4").text(),
                            'ctaText': 'Unsubscribe'
                        });
                    } else if (ajax.responseJSON.wasProcessed && status == 3) {
                        subscriptions.unSetButton(this);
                        dataLayerPush(gtm = {
                            'event': 'account',
                            'accountSection': 'settings & subscriptions',
                            'accountAction': "" + $(this).closest('.panel-cta').find("h4").text(),
                            'ctaText': 'Subscribe'
                        });
                    } else {
                        subscriptions.error();
                    }
                } else {
                    subscriptions.error();
                }
            } else {
                var isSuscribed = $(this).hasClass("subscribed");
                if (isSuscribed) {
                    var ajax = subscriptions.permissionStopEmail();
                    if (ajax.status == 200) {
                        if (ajax.responseJSON.wasProcessed) {
                            subscriptions.setButton(this);
                            $(this).removeClass("subscribed");
                            dataLayerPush(gtm = {
                                'event': 'account',
                                'accountSection': 'settings & subscriptions',
                                'accountAction': "" + $(this).closest('.panel-cta').find("h4").text(),
                                'ctaText': 'Unsubscribe'
                            });
                        } else {
                            subscriptions.error();
                        }
                    } else {
                        subscriptions.error();
                    }
                } else {
                    var ajax = subscriptions.permissionResumeEmail();
                    if (ajax.status == 200) {
                        if (ajax.responseJSON.wasProcessed) {
                            subscriptions.unSetButton(this);
                            $(this).addClass("subscribed");
                            dataLayerPush(gtm = {
                                'event': 'account',
                                'accountSection': 'settings & subscriptions',
                                'accountAction': "" + $(this).closest('.panel-cta').find("h4").text(),
                                'ctaText': 'Subscribe'
                            });
                        } else {
                            subscriptions.error();
                        }
                    }
                }
            }
        });
    } //if
}); //function