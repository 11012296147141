"use strict";

$(function () {
    if ($(".c-027").length > 0) {
        //let url =  'https://qa.neamb.com/api/SearchService/GetContentPages';
        //let url = 'http://integ.neamb.com/api/SearchService/GetContentPages';
        var url = $(location).attr('origin') + "/api/SearchService/GetContentPages";
        var contentFilter = {
            data: {
                //"pageId": "100f6e4d-b28e-4786-b877-2697c67d4b16",//integ
                //"datasourceId": "100f6e4d-b28e-4786-b877-2697c67d4b16",//integ
                //"pageId": "b57e1ba7-5150-40d9-bc2b-8aee76850c7c",//qa
                //"datasourceId": "b57e1ba7-5150-40d9-bc2b-8aee76850c7c",//qa
                "pageId": $('.c-027').data('page-id'),
                "datasourceId": $('.c-027').data('datasource-id'),
                "filters": [],
                "skip": 0,
                "take": 8
            },
            loader: $(".loader"),
            contextFilters: $("#filters"),
            contextFiltersMobile: $("#mobile-filters"),
            contextCards: $("#items"),
            seeMore: $(".more-items"),
            filters: [],
            init: function init() {
                this.getCards();
                this.getFilters();
            },
            getCards: function getCards(el) {
                var data = this.data;
                this.loader.insertAfter(this.contextCards).show();
                if (el == "more") {
                    data.skip = $(".c-027 .card").length;
                    data.take = "4";
                } else {
                    this.contextCards.html("");
                }
                var ajax = this.requestData(data);
                this.filters = ajax.responseJSON.AllCategories;
                if (ajax.readyState == 4 && ajax.status == 200) {
                    this.contextCards.siblings($(".loader").remove());
                    if (ajax.responseJSON.PageCards.length > 0) {
                        this.contextCards.append(this.populateCards(ajax.responseJSON.PageCards));
                        $(".c-027 .card").length < ajax.responseJSON.Total ? this.seeMore.show() : this.seeMore.hide();
                    } else {
                        this.seeMore.hide();
                    }
                }
            }, //getCards
            getFilters: function getFilters() {
                this.loader.insertAfter(this.contextFilters).show();
                this.contextFilters.siblings($(".loader").remove());
                this.contextFilters.append(this.populateFilters(this.filters, "desktop"));
                this.contextFiltersMobile.html(this.populateFilters(this.filters, "mobile"));
                this.doSlick();
            },
            doSlick: function doSlick() {
                this.contextFiltersMobile.slick({
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    edgeFriction: 0.50,
                    swipe: true,
                    infinite: false,
                    arrows: false,
                    variableWidth: true
                });
            },
            populateFilters: function populateFilters(filters, app) {
                var htmlFilters = "";
                $.each(filters, function (index, el) {
                    if (index == 5 && app != "mobile") {
                        htmlFilters += "<a id=\"more-filters\" href=\"#\" class=\"btn btn-bordered-blue btn-filters\"><span>More...</span></a>";
                    }
                    htmlFilters += "<a href=\"#\" id=\"" + index + "\" title=\"" + el + "\" class=\"btn btn-bordered-blue btn-filters\"><span>" + el + "</span></a>";
                });
                return htmlFilters;
            },
            populateCards: function populateCards(cards) {
                var htmlCards = "";
                var image = void 0;
                $.each(cards, function (index, el) {
                    if (el.ThumbnailSrc) {
                        image = "<img src=\"" + el.ThumbnailSrc + "\" alt=\"" + (el.ThumbnailAlt ? el.ThumbnailAlt : 'Alt Text') + "\"></img>";
                    } else {
                        image = '';
                    }
                    htmlCards += "<div class=\"col-xs-12 col-sm-6 col-md-3 card\">\n                        <a  onClick=\"" + el.GtmAction + "\" href=\"" + el.Cta + "\">\n                            " + image + "\n                            <div class=\"genre\">" + el.Genre + "</div>\n                                <h3>" + el.Title + "</h3>\n                                <p>" + el.Description + "</p>\n                        </a>\n                    </div>";
                });
                return htmlCards;
            },
            requestData: function requestData(data) {
                return $.ajax({
                    data: data,
                    dataType: 'json',
                    async: false,
                    type: 'POST',
                    url: url
                });
            } //requestData


            /******************************************************************************************************** */
            /******************************************************************************************************** */

        };$(window).width() < 992 ? contentFilter.data.take = 4 : contentFilter.data.take = 8;
        contentFilter.init();
        $(".more-items").on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            contentFilter.getCards("more");
            var gtm = {
                'event': 'navigation',
                'navType': 'load more',
                'navText': 'See More'
            };
            dataLayerPush(gtm);
        });
        $(window).on("resize", function (e) {
            contentFilter.data.skip = 0;
            window.innerWidth < 992 ? contentFilter.data.take = 4 : contentFilter.data.take = 8;
            contentFilter.getCards();
        });

        $('#more-filters').on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (contentFilter.contextFilters.hasClass("open")) {
                contentFilter.contextFilters.removeClass("open");
                $(this).find("span").text("More...");
            } else {
                contentFilter.contextFilters.addClass("open");
                $(this).find("span").text("Less");
            }
        });
        $("#filters .btn-filters").on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $this = $(this);
            if ($this.attr('id') != "more-filters") {
                if ($this.hasClass('active')) {
                    contentFilter.data.filters.splice($.inArray($this.text(), contentFilter.data.filters), 1);
                    $this.toggleClass('active');
                } else {
                    $this.toggleClass('active');
                    contentFilter.data.filters.push($this.text());
                }
                contentFilter.data.skip = 0;
                contentFilter.data.take = 8;
                contentFilter.getCards();
            } //if
            var gtm = {
                'event': 'advice filter',
                'ctaText': "" + $this.find("span").text()
            };
            dataLayerPush(gtm);
        });
        //--------------------------------------------------------------------------------------------------------------
        // MOBILE FILTERS
        $(document).on("click", "#mobile-filters .btn-filters", function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $this = $(this);
            if (!$this.hasClass("active")) {
                $this.addClass("active");
                $('#mobile-filters').slick('slickRemove', $this.index("#mobile-filters .btn-filters"));
                $('#mobile-filters').slick('slickAdd', $this.parent().clone(), 0, true);
                $('#mobile-filters').slick('slickGoTo', 0);
                contentFilter.data.filters.push($this.text());
                contentFilter.getCards();
            }
        });
        $(document).on("click", "#mobile-filters .btn-filters.active", function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $this = $(this);
            if ($this.hasClass("active")) {
                $this.removeClass("active");
                index = $this.attr("id");
                $('#mobile-filters').slick('slickRemove', $this.index("#mobile-filters .btn-filters"));
                //$('#mobile-filters').slick('slickAdd', $this.parent().clone() , index == 0 ?  index : index - 1, index == 0 ?  true : false );
                $('#mobile-filters').slick('slickAdd', $this.parent().clone(), $("#mobile-filters .btn-filters").length - 1);
                contentFilter.data.filters.pop($this.text());
                contentFilter.getCards();
            }
        });
    } //if
});