"use strict";

$(document).ready(function () {
    if ($("#rateQuoteContent").length > 0) {
        var map = {
            endpoint: "/api/",
            //endpoint: "http://integ.neamb.com/api/",
            form: $('form#areaForm'),
            button: $("#submit"),
            rqzip: $("#rqzip"),
            rqage: $("#rqage"),
            zipDiv: $("#zipTxt"),
            ageDiv: $("#ageTxt"),
            zipError: $('#zipErrorMsgRQ'),
            ageError: $('#ageErrorMsgRQ'),
            stateSelect: $("#stateSelect"),
            noDataMsg: $("#noDataMsg"),
            loader: $("#table-loader"),
            formLoader: $("form div + .loader"),
            table: $("#table"),
            tableBody: $("#tbody"),
            dataTable: {
                payload: {
                    "state": "",
                    "zip": "",
                    "age": 0
                },
                "url": ""
            },
            clear: function clear() {
                this.ageError.hide();
                this.zipError.hide();
                this.table.hide();
                this.noDataMsg.hide();
                this.dataTable.payload.zip = "";
                this.dataTable.payload.age = 0;
                this.zipDiv.hide();
                this.ageDiv.hide();
                this.buttonState(false);
            },
            clearInputs: function clearInputs() {
                this.rqzip.val("");
                this.rqage.val("0");
            },
            buttonState: function buttonState(_buttonState) {
                _buttonState ? this.button.removeClass('disabled').attr('disabled', false) : this.button.addClass('disabled').attr('disabled', 'disabled');
            },
            setSelect: function setSelect(id) {
                this.stateSelect.val(id);
                this.dataTable.payload.state = id;
            },
            validatePreEmail: function validatePreEmail(stateCode) {
                var _this = this;

                map.formLoader.show();
                this.clear();
                var data = {
                    payload: {
                        "state": stateCode
                    },
                    "url": this.endpoint + "Calculator/ValidateState"
                };
                var response = this.requestData(data);
                response.done(function () {
                    var status = response.responseJSON.Status;
                    var none = 0;
                    var invalid = 1;
                    var specifyZip = 2;
                    var specifyAge = 4;
                    if ((status & invalid) == invalid) {
                        $("#invalid").show();
                        //Show message if needed.
                    } else {
                        if ((status & specifyZip) == specifyZip) {
                            //Show message if needed.
                            $("#zipTxt").show();
                        } else {
                            //Set Zip to empty.
                            $("#zipTxt").hide();
                            _this.dataTable.payload.zip = 0;
                        }
                        $("#ageTxt").show();
                    } //if
                });
            },
            requestTable: function requestTable() {
                var _this2 = this;

                map.loader.show();
                this.dataTable.url = this.endpoint + "Calculator/GetQuote";
                var response = this.requestData(this.dataTable);
                response.done(function () {
                    if (response.responseJSON.Status != 1) {
                        _this2.drawTable(response);
                        _this2.noDataMsg.hide();
                    } else {
                        _this2.noDataMsg.text("No data available, please try again").show("slow", function () {
                            _this2.noDataMsg.scrollView();
                        });
                    }
                });
            },
            drawTable: function drawTable(data) {
                var _this3 = this;

                if (data.responseJSON.Status == 4) {
                    var table = data.responseJSON.Plans;
                    var tr = "";
                    var thead = "<tr><th></th>";
                    for (var i = 0; i < Object.keys(table).length; i++) {
                        tr += "<tr><td><a href=\"" + table[i]["Info"] + "\" target=\"_blank\">" + table[i]["Name"] + "</a>";
                        for (var j = 0; j < table[i]["Quotes"].length; j++) {
                            tr += "<td> " + table[i]["Quotes"][j]["Price"] + "</td>";
                        }
                        tr += "</td></tr>";
                    } //for

                    for (var i = 0; i < table[0]["Quotes"].length; i++) {
                        thead += "<th>" + table[0]["Quotes"][i]["Age"] + "</th>";
                    }
                    thead += "</tr>";
                    this.table.find("thead").html(thead);
                    this.tableBody.html(tr);
                    this.table.show("slow", function () {
                        _this3.table.scrollView();
                    });
                } else {
                    this.noDataMsg.text("No data available, please try again").show("slow", function () {
                        _this3.noDataMsg.scrollView();
                    });
                }
            },
            requestData: function requestData(data) {
                return $.ajax({
                    data: data.payload,
                    dataType: 'json',
                    type: 'POST',
                    url: data.url,
                    complete: function complete() {
                        map.formLoader.hide();
                        map.loader.hide();
                    },
                    error: function error() {
                        map.formLoader.hide();
                        map.loader.hide();
                        map.noDataMsg.text("There was en error please close this message, reload the page and try again").show("slow", function () {
                            map.noDataMsg.scrollView();
                        });
                    }
                });
            } //requestData


            //EVENTS
        };$('area').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr('id');
            map.setSelect(id);
            map.validatePreEmail(id);
            map.clearInputs();
        });

        map.button.on("click", function (e) {
            e.preventDefault();
            map.table.hide();
            map.noDataMsg.hide();
            if (map.rqage.is(':visible')) {
                var age = map.rqage.val();
                age == '0' ? map.ageError.show() : (map.ageError.hide(), map.dataTable.payload.age = age);
            } else {
                map.dataTable.payload.age = 0;
            }
            if (map.rqzip.is(':visible')) {
                var zip = map.rqzip.val();
                if (zip.length == 5) {
                    isNaN(zip) ? map.zipError.show() : (map.zipError.hide(), map.dataTable.payload.zip = zip);
                } else {
                    map.zipError.show();
                }
            } else {
                map.dataTable.payload.zip = "";
            }
            if (!map.zipError.is(':visible') && !map.ageError.is(':visible')) {
                map.requestTable();
            }
        });

        $("#stateSelect").change(function (e) {
            e.preventDefault();
            var id = $(this).val();
            map.setSelect(id);
            map.validatePreEmail(id);
            map.clearInputs();
        });

        map.rqage.on("change", function (e) {
            e.preventDefault();
            if ($(this).val() != "0") {
                map.buttonState(true);
            } else {
                map.buttonState(false);
            }
        });
    } //if*/
});