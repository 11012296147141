"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

jQuery(function () {
    $(".navbar-toggle").on("click", function () {
        $(this).toggleClass("collapsed");
    });

    $('.dropdown-submenu > a').on("click", function (e) {
        $(this).next('ul').toggle();
        $(this).toggleClass('open');
        e.stopPropagation();
        e.preventDefault();
    });

    $('.profile-menu-accordion').on("click", function (e) {
        $(this).toggleClass("active");
        $(this).next().toggle();
        e.stopPropagation();
        e.preventDefault();
    });

    if ($('.sticky').length > 0) {
        var distance = $('.sticky').offset().top;
        if ($(".items-fixed.buttons > a").length == 1) {
            $("#middle-menu ul").addClass("single");
        }
        $(window).on("scroll", function () {
            if ($(this).scrollTop() >= distance) {
                $('.sticky').addClass('fixed');
                $('body').addClass('body-fixed');
            } else {
                $('.sticky').removeClass('fixed');
                $('body').removeClass('body-fixed');
            }
        });
    }

    $(".profile-toggle").on("click", function () {
        $(this).toggleClass("collapsed");
        var isOpen = $(this).hasClass("collapsed");
        if (isOpen) {
            showMobileOverlay();
            $("#mobile-menu").addClass("d-none");
            $("#profile-menu").removeClass("d-none");
            $(".nav-center").removeClass('show');
            $('.navbar-toggler').addClass('collapsed');
            $('.navbar-toggle').addClass('collapsed');

            // change icon for mobile menu
            $('.navbar-toggler').addClass('collapsed');
        } else {
            hideOverlay();
            hideOverlayLayers();
        }
    });

    var $showloginCurtain = $('a.show-login-curtain');
    var $firstCurtain = $("div.show-login-curtain");
    var $loginCurtain = $('.login-curtain');
    var $loginDesktopLink = $('.login-desktop-link');
    var $colduser = $('.dropdown-toggle.cold');

    $loginCurtain.on({
        mouseenter: function mouseenter() {
            $(this).prev().addClass("show");
        },
        mouseleave: function mouseleave() {
            $(this).hide().prev().removeClass("show");
        }
    });

    $loginDesktopLink.on({
        mouseenter: function mouseenter() {
            $loginCurtain.show().prev().addClass("show");
        },
        mouseleave: function mouseleave() {
            setTimeout(function () {
                if (!$(".login-curtain:hover").length) {
                    $loginCurtain.hide().prev().removeClass("show");
                }
            }, 300);
        }
    });

    if ($(".dropdown-toggle.cold").length > 0) {
        if ($showloginCurtain.length > 0) {
            $firstCurtain.width("235");
            $loginCurtain.show().delay(5000).fadeOut();
        }
        $loginCurtain.on("mouseenter", function () {
            $(this).stop(true, true);
        });
        $loginCurtain.on("mouseleave", function () {
            $loginCurtain.find('.extended-sign-in-menu ').removeClass('d-none');
            $firstCurtain.width("420");
        });

        if ($colduser) {
            $('a.dropdown-toggle.cold').on('mouseenter', function () {
                $loginCurtain.addClass('pointed');
                $firstCurtain.width("420");
                $loginCurtain.find('.extended-sign-in-menu ').removeClass('d-none');
                $(this).addClass('show');
                $(this).parent().find('.login-curtain').addClass('show');
            });
            $('div.show-login-curtain').on('mouseleave', function () {
                $(this).removeClass('show');
                $(this).parent().find('.login-curtain').removeClass('show');
            });
            $('a.dropdown-toggle.cold').on('mouseleave', function () {
                $('div.show-login-curtain').on('mouseenter', function () {
                    $(this).addClass('show');
                    $(this).parent().find('.login-curtain').addClass('show');
                });
                $(this).removeClass('show');
                $(this).parent().find('.login-curtain').removeClass('show');
            });
        }
    }

    $('.important-messages a').on('click', function (e) {
        e.preventDefault();
        $('.account-links').slideToggle();
        $('.important-message-container').slideToggle();
    });
    $('.important-messages_lists .signin-title').on('click', function () {
        $('.account-links').slideToggle();
        $('.important-message-container').slideToggle();
    });
    // see more 
    $('.important-message__see-more').on('click', function () {
        $('.message-list').hide();
        $(this).parents('.message-list').show().addClass('message-expanded');
        $(this).hide();
        $('.important-message__see-less').show();
    });
    // see less
    $('.important-message__see-less').on('click', function () {
        importantMessageSeeLess();
    });
    //show more only if exceeds parents width
    $('#navbarMessagesMenuLink').on('click', function (e) {
        isTextOverflowing();
    });
});

window.addEventListener('resize', function () {
    isTextOverflowing();
    if ($('.message-list').hasClass('message-expanded')) {
        importantMessageSeeLess();
    }
});

function importantMessageSeeLess() {
    $('.message-list').show().removeClass('message-expanded');
    $('.important-message__see-more').show();
    $('.important-message__see-less').hide();
    isTextOverflowing();
}

function isTextOverflowing() {
    $(".important-message__see-more").hide();
    var $tempElement = $(".message-description");
    var $myElements = $('.message-description p');

    $myElements.each(function (index, element) {
        var fatherWidth = $tempElement[index].getBoundingClientRect().width;
        var elementWidth = element.getBoundingClientRect().width;
        if (elementWidth > 0) {
            if (elementWidth >= fatherWidth) {
                $(element).siblings('.important-message__see-more').show();
            }
        }
    });
}

$("#main-nav").on("show.bs.collapse", function () {
    $('html').addClass("disableScrollSafari");
    $('body').addClass("disableScrollSafari");
}).on("hide.bs.collapse", function () {
    $('html').removeClass("disableScrollSafari");
    $('body').removeClass("disableScrollSafari");
    closeNavMenu();
});

function openNavMenu() {
    // showMobileOverlay();
    $('.navbar-toggler').removeClass('collapsed');
    $("#mobile-menu").removeClass("d-none");
    $("#profile-menu").addClass("d-none");

    if ($(".profile-toggle.collapsed")) {
        hideProfile();
    }

    // change icon for profile menu
    $(".profile-toggle").removeClass("collapsed");
}
function hideProfile() {
    $(".overlay-mobile").css({
        "opacity": "0",
        "height": "0",
        "z-index": "-1000"
    });
}

function closeNavMenu() {
    hideOverlay();
    hideOverlayLayers();
    $('.navbar-toggler').addClass('collapsed');
    $('.navbar-toggle').addClass('collapsed');
}

function showMobileOverlay() {
    $('html, body').addClass("disableScrollSafari");
    $(".overlay-mobile").css(_defineProperty({
        "opacity": "1",
        "height": "100%",
        "z-index": "999999"
    }, "height", getOverlayHeight()));
}

function hideOverlay() {
    $('html, body').removeClass("disableScrollSafari");
    $(".overlay-mobile").css({
        "opacity": "0",
        "height": "0",
        "z-index": "-1000"
    });
}

function hideOverlayLayers() {
    $("#mobile-menu").addClass("hidden");
    $("#profile-menu").addClass("hidden");
}

function getOverlayHeight() {
    var top = document.getElementsByClassName('overlay-mobile')[0].offsetTop;
    return "calc(100% - " + top + "px)";
}