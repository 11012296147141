"use strict";

$(function () {
	if ($("form#login").length > 0) {
		var $loginForm = "form#login";
		setValidateFormOnKeyUp();

		$($loginForm).validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("login", 2);
			},
			ignore: ".ignore",
			rules: {
				email: {
					required: true,
					isValidEmail: true
				}
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form, event) {
				event.preventDefault();
				$($loginForm).find(".fa-spinner").show();
				$.ajax({
					dataType: 'json',
					type: 'POST',
					url: '/api/AuthenticationAccount/SavePostLoginAction',
					success: function success(response) {
						dataLayerPush({
							'event': 'login',
							'loginAction': 'submit',
							'loginResult': 'attempt'
						}, this);
						form.submit();
					}
				});
				return false;
			},
			invalidHandler: function invalidHandler(event, validator) {
				$($loginForm).find(".fa-spinner").hide();
			}
		});

		$($loginForm + " a#submitLogin").on("click", function (e) {
			e.preventDefault();
			$($loginForm).find(".fa-spinner").show();
			setTimeout(function () {
				var isFormValid = $($loginForm).valid();
				if (isFormValid) {
					var response = executeloginajax();
					if (response == false) {
						$($loginForm).find(".fa-spinner").hide();
					}
				} else {
					$($loginForm).find(".fa-spinner").hide();
				}
			}, 500);
		});
	}

	/**
  * Set Login form validation on key up, change color button when valid
  */
	function setValidateFormOnKeyUp() {
		$($loginForm + " input").on("keyup", function (e) {
			setTimeout(function () {
				setupAccentWhenValidForm("login", 2);
			}, 500);
			if (e.key === "Enter") {
				e.preventDefault();
				$("#submitLogin").trigger("click");
			}
		});
	}
});